import {
  Component,
  Input, Output, ElementRef, ViewChild,
  AfterViewInit, EventEmitter, TemplateRef,
  ViewEncapsulation,
  HostListener,
  HostBinding
} from '@angular/core';
import { NgxDropzoneService, FilePreview, FileSelectResult } from './ngx-dropzone.service';
import { resolve } from 'url';

@Component({
  selector: 'ngx-dropzone',
  template: `<input type="file" #fileInput class="file-input" (change)="onFilesSelected($event)" [multiple]="multiple"
  [accept]="accept" />
<div class="dropzone" (click)="showFileSelector()">
  <p *ngIf="service.previews.length === 0">{{ label }}</p>
  <div class="preview" [class.limit-width]="!p.data" *ngFor="let p of service.previews">
    <img *ngIf="p.data" [src]="p.data">
    <span *ngIf="p.data">{{ p.filename }}</span>
    <div *ngIf="!p.data" class="preview-item">
      <span>{{ p.filename }}</span>
    </div>
  </div>
</div>
`,
  styles: [`:host(){display:flex;height:180px;cursor:pointer;background:#fff;color:#717386;border:2px dashed #717386;border-radius:5px;font-size:16px}:host().hovered{border:2px solid #717386;color:#dfdfe4}:host().disabled{opacity:.5;cursor:no-drop}:host()>.dropzone{display:flex;flex-direction:row;justify-content:flex-start;align-items:center;width:100%;overflow-x:auto}:host()>.dropzone>p{margin:10px auto}:host()>.dropzone>.preview{height:80%;position:relative;text-align:center;padding:0 10px;width:-webkit-max-content;width:-moz-max-content;width:max-content}:host()>.dropzone>.preview.limit-width{max-width:25%}:host()>.dropzone>.preview>img{max-height:100%;border-radius:5px;opacity:.8}:host()>.dropzone>.preview>span{position:absolute;top:50%;left:50%;-webkit-transform:translate(-50%,-50%);transform:translate(-50%,-50%);color:#fff}:host()>.dropzone>.preview>.preview-item{display:flex;text-align:center;align-items:center;height:100%;padding:0 40px;overflow-wrap:break-word;border-radius:5px;background-image:linear-gradient(to top,#ededed,#efefef,#f1f1f1,#f4f4f4,#f6f6f6)}:host()>.dropzone>.preview>.preview-item>span{color:#717386;max-width:100%}.file-input{display:none}`],
  providers: [NgxDropzoneService] // Create a new service instance for each component.
})
export class NgxDropzoneComponent {

  constructor(
    private host: ElementRef,
    public service: NgxDropzoneService
  ) { }

  @Input() label = 'Drop your files here (or click)';
  @Input() multiple = true;
  @Input() accept = '*';
  @Input() maxFileSize: number;
  @Input() showPreviews = false;
  @Input() preserveFiles = true;

  @Output() filesAdded = new EventEmitter<File[]>();
  @Output() filesRejected = new EventEmitter<File[]>();

  @HostBinding('class.disabled') @Input() disabled = false;
  @HostBinding('class.hovered') hovered = false;

  @ViewChild('fileInput') private fileInput: ElementRef;

  showFileSelector() {
    if (!this.disabled) {
      this.fileInput.nativeElement.click();
    }
  }

  reset() {
    this.service.reset();
  }

  onFilesSelected(event) {
    const files: FileList = event.target.files;

    this.handleFileDrop(files).then(() => {
      // Reset the file input value to trigger the event on new selection.
      (this.fileInput.nativeElement as HTMLInputElement).value = '';
    });
  }

  /**
   * UPDATE 10.03.2019:
   * Refactored to use HostListener and HostBindings to allow
   * for easier style overwriting from outside the component.
   */
  @HostListener('dragover', ['$event'])
  onDragOver(event) {
    if (this.disabled) {
      return;
    }

    this.preventDefault(event);
    this.hovered = true;
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event) {
    this.hovered = false;
  }

  @HostListener('drop', ['$event'])
  onDrop(event) {
    this.preventDefault(event);
    this.hovered = false;
    this.handleFileDrop(event.dataTransfer.files);
  }

  private async handleFileDrop(files: FileList): Promise<void> {
    return new Promise<void>(resolve => {
      if (this.disabled) {
        return;
      }

      this.service.parseFileList(files, this.accept, this.maxFileSize,
        this.multiple, this.preserveFiles, this.showPreviews)
        .then((result: FileSelectResult) => {
          this.filesAdded.next(result.addedFiles);

          if (result.rejectedFiles.length) {
            this.filesRejected.next(result.rejectedFiles);
          }

          resolve();
        });
    });
  }

  private preventDefault(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }
}
